<!-- 微信用户列表 -->
<template>
    <div class="warp">
      <el-card class="warp-content">
        <!-- 搜素区域 -->
        <div class="page-select">
          <el-row style="width: 100%; margin: 0 0 15px 0">
            <el-col :span="24" style="text-align: right">
<!--              <el-input-->
<!--                placeholder="请输入用户昵称"-->
<!--                class="search-input"-->
<!--                prefix-icon="search"-->
<!--                size="small"-->
<!--                @input="searchInput"-->
<!--                v-model="userInput"-->
<!--                clearable-->
<!--              ></el-input>-->
              <el-input
                placeholder="请输入手机号"
                class="search-input"
                prefix-icon="search"
                size="small"
                @input="searchInput"
                v-model="phoneInput"
                clearable
              ></el-input>
<!--              <el-form-->
<!--              label-width="100px"-->
<!--              :model="formData"-->
<!--              :rules="rules"-->
<!--              ref="ruleForm"-->
<!--              class="formClass"-->
<!--              >-->
<!--                <el-form-item label="注册时间：" prop="alarTime">-->
<!--                  <el-date-picker-->
<!--                    v-model="formData.alarTime"-->
<!--                    unlink-panels-->
<!--                    type="datetimerange"-->
<!--                    size="small"-->
<!--                    range-separator="至"-->
<!--                    start-placeholder="起始时间"-->
<!--                    end-placeholder="结束时间"-->
<!--                    format="YYYY-MM-DD HH:mm:ss"-->
<!--                    value-format="YYYY-MM-DD HH:mm:ss"-->
<!--                    style="margin-top: 6px;"-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-form>-->
              <el-button type="primary" size="small" @click="searchInput">搜索</el-button>
            </el-col>
          </el-row>
        </div>

        <!-- 表格区域 -->
        <div class="page-container">
          <el-table
            ref="tableRef"
            :data="tableData"
            style="width: 100%"
            max-height="630px"
            :header-cell-style="{ background: '#F2F4F8' }"
            @selection-change="handleSelectionChange"
          >
<!--            <el-table-column type="selection" align="center" width="80" />-->
            <el-table-column
              v-for="(item, index) in tableColumnData"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template  #default="{ row }" v-if="item.prop === 'headUrl'">
                <el-image style="width: 50px; height: 50px" :src="row.headUrl" fit="scale-down" />
<!--                <img :src=row.headUrl />-->
              </template>
            </el-table-column>
<!--            <el-table-column label="操作" align="center">-->
<!--              <template #default="{ row }">-->
<!--                <el-button type="text" @click="editClick(row)">编辑</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </div>
        <!-- 分页区域 -->
        <div class="page-foot">
          <page-nation
            :total="total"
            @pageChange="handleSizeChange"
            @currentChange="handleCurrentChange"
          ></page-nation>
        </div>
        <!--编辑用户-->
        <el-dialog
            v-model="dialogVisible"
            width="40%"
            :title="dialogTitleName"
            :close-on-click-modal="false"
            @closed="resetForm"
        >
          <el-form
              label-width="95px"
              :inline="true"
              :model="editFormData"
              :rules="editDeviceRules"
              ref="editRuleFormRef"
          >
            <el-row :gutter="20">
              <el-col :span="22">
                <el-form-item label="昵称：" prop="deviceMac">
                  <el-input
                    placeholder="请输入昵称"
                    disabled
                    v-model="editFormData.deviceMac"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
<!--            <el-row :gutter="20">-->
<!--              <el-col :span="22">-->
<!--                <el-form-item label="微信ID：" prop="deviceVersion">-->
<!--                  <el-input-->
<!--                      placeholder="请输入微信ID"-->
<!--                      disabled-->
<!--                      v-model="editFormData.deviceVersion"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
            <el-row :gutter="20">
              <el-col :span="22">
                <el-form-item label="状态：" prop="deviceStatus">
                  <el-select
                      v-model="editFormData.deviceStatus"
                      placeholder="请选择状态"
                      class="search-input-class"
                      @change="jointFn" >
                    <el-option
                        v-for="item in deviceStatusOptions"
                        :key="item.v "
                        :label="item.label"
                        :value="item.value" >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="resetEditForm">取消 </el-button>
              <el-button type="primary" @click="submitEditForm('allocateDeviceRuleFormRef')" >保存 </el-button>
            </span>
          </template>
        </el-dialog>
      </el-card>
    </div>
  </template>

      <script>
  import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
  import pageNation from "@/components/base/pageNation.vue";
  import { requestApi } from "@/utils/requestApi";
  import { debounces } from "@/common/utils";
  import {getByUserTypeApi} from "@/api/userManagement/userList";
  export default {
    name: "AccountSearch",
    components: { pageNation },
    setup() {
      const { proxy } = getCurrentInstance();
      const data = reactive({
        tableData: [], // 表格数据
        total: 0,
        pageSize: 10,
        currentPage: 1,
        userInfo: null,
        userLevel: 0, //用户等级
        selection: [],
        disabled: true,
        formData: {
          alarTime: [], //起始时间
        },
        tableColumnData: [
          // {
          //   prop: "username",
          //   label: "昵称",
          // },
          {
            prop: "userId",
            label: "微信ID",
          },
          {
            prop: "phone",
            label: "电话",
          },
          // {
          //   prop: "headUrl",
          //   label: "头像",
          // },
          {
            prop: "balance",
            label: "余额",
          },
          // {
          //   prop: "practical",
          //   label: "实际支付金额",
          // },
          {
            prop: "practical",
            label: "充值总金额"
          },
          {
            prop: "wxTotal",
            label: "微信消费总额"
          },
          {
            prop: "walletTotal",
            label: "钱包消费总额"
          },
          {
            prop: "presenter",
            label: "赠送金额",
          },
          // {
          //   prop: "accountType1",
          //   label: "状态",
          // },
          {
            prop: "createTime",
            label: "注册日期",
          },
        ],
        userInput: "", //用户昵称搜索
        phoneInput: '',
        searchDeviceAddress: "",
        searchDeviceStatus: "",
        searchDeviceAgency: '',
        searchChargnt: '',
        deviceAgencyList: [
          {
            label: "特瑞萨",
            value: 0,
          },
        ],
        deviceChargntList: [{
          label: '1',
          value: 0
        }],
        // 编辑用户对话框
        dialogVisible: false,
        dialogTitleName:'编辑用户',
        // 编辑用户form
        editFormData:{

        },
        editDeviceRules: {},
        // 编辑用户 状态选项
        deviceStatusOptions:[
          {value: 0, label: '正常'},
          {value: 1, label: '禁用'},
        ],
      });
      onMounted(() => {
        proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
        methods.getByUserType();
      });

      // 节流搜索按钮
      const searchInput = debounces(() => {
        methods.getByUserType();
      }, 500);


      const methods = {
        // 点击编辑
        editClick(row) {
          console.log(row);
          data.dialogVisible = true
          data.editFormData = {
            username: row.username,
            deviceStatus: row.deviceStatus,
          }
        },
        // 编辑确认
        submitEditForm() {
          data.dialogVisible = false
        },
        resetEditForm() {
          data.dialogVisible = false
        },
        async getByUserType () {
          await requestApi(
              () => {
                const params = {
                  current: data.currentPage,
                  size: data.pageSize,
                  type: 2, // 微信
                  phone: data.phoneInput,
                  username: data.userInput,
                  webOrWxType: 1,    // web端
                  merchantId: data.userInfo.merchantId,
                  level: proxy.userLevel
                }
                return getByUserTypeApi(params)
              },
              (res) => {
                data.tableData = res.data.records
                if (data.tableData.length > 0) {
                  for (let i = 0; i < data.tableData.length; i++) {
                    data.tableData[i].balance = (data.tableData[i].balance / 100).toFixed(2)
                    data.tableData[i].practical = (data.tableData[i].practical / 100).toFixed(2)
                    data.tableData[i].presenter = (data.tableData[i].presenter / 100).toFixed(2)
                    data.tableData[i].refillTotal = (data.tableData[i].refillTotal / 100).toFixed(2)
                    data.tableData[i].wxTotal = (Number(data.tableData[i].wxTotal) / 100).toFixed(2)
                    data.tableData[i].walletTotal = (Number(data.tableData[i].walletTotal) / 100).toFixed(2)
                  }
                }
                data.total = res.data.total
                data.currentPage = res.data.current
                data.pageSize = res.data.size
              }
          )
        },
        // 表格分页 改变每页数量时触发
        handleSizeChange(val) {
          proxy.pageSize = val
          methods.getByUserType()
        },
        // 表格分页 改变页数时触发
        handleCurrentChange(val) {
          proxy.currentPage = val
          methods.getByUserType()
        },

      };
      return {
        ...toRefs(data),
        searchInput,
        ...methods,
      };
    },
  };
  </script>
  <style lang="less" scoped>
  .warp {
    height: 100%;
    .warp-content {
      height: 100%;
    }
  }
  .page-select {
    width: 100%;
    margin: 0 auto;
    .formClass {
      display: inline-block;
      margin-right: 4px;
    }
  }
  .page-container {
    width: 100%;
    //margin-top: -28px;
  }
  .page-foot {
    width: 100%;
    margin-top: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .my-btn {
      height: 28px;
    }
  }
  .search-input {
    width: 202px;
    margin-right: 4px;
    margin-top: 6px;
  }

  .search-input-class {
    width: 100%;
  }
  /deep/.el-form-item__label {
    white-space: nowrap;
  }
  /deep/.el-form-item {
    width: 100%;
  }
  </style>
