import { axios } from '@/utils/request'

const api = {
    getByUserType: '/company/user/getByUserType', // 根据用户类型查询用户
}
export default api

export const getByUserTypeApi = (params) => {
    return axios({
        url: api.getByUserType,
        method: 'post',
        params: params
    })
}
